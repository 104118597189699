import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from "@components/Layout";
import SEO from "@components/SEO";
import JumbotronComponent from "@components/Jumbotron";
import Flashcard from "@components/Flashcard";
import SubHeading from "@components/Subheading";
import { publicIdToLocal } from "@utils/cloudinary";
import LocationContext from "@components/LocationContext";
import { graphql } from "gatsby"

const CSRMainCSS = styled.div`
  width: ${props => (props.showFlashcard ? "70vw" : "100vw")};
  margin: 0 auto;
  padding-bottom: 150px;

  @media (max-width: 600px) {
    width: 95vw;
  }

  @media (min-width: 1024px) {
    padding-top: 100px;
  }

  @media (max-width: 1024px) {
    padding-top: 10vh;
  }

  .subtitle {
    h2 {
      padding-top: 3rem;
      padding-bottom: 1rem;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: green;
    }

    .intro {
      text-align: center;
    }

    p {
      font-weight: 500;
      margin-bottom: 1rem;
      line-height: 1.5rem;
    }

    @media (max-width: 1024px) {
      p {
        font-size: 1rem;
      }
    }
  }

  .ant-card-body {
    padding: 0;
  }

  .flashcards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

const CardSectionCSS = styled.div`
  padding: 1em 0;
`;

const DesktopCardCSS = styled.div`
  display: flex;

  h3 {
    font-size: 1.5rem;
    font-weight: 800;
  }

  .desktop-picture,
  .desktop-words {
    height: 500px;
    width: 50vw;
  }

  .desktop-words {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    padding: 8%;
  }
`;

const parseIntro = sentences =>
  sentences.split("\n").map((sentence, unique) => <p key={unique} className="intro">{sentence}</p>);

const parseBackContent = sentences =>
  sentences
    .split("\n")
    .map((sentence, index) => <p className="backContent" key={index}>{sentence}</p>);

const subheadingToIdMap = {
  talentNurturing: "talents",
  environment: "environment-protection",
  welfare: "welfare",
};

const DesktopCard = ({ t, imgUrl, overlayKey, backContent, index }) => {
  const picture = (
    <div
      className="desktop-picture"
      style={{
        clipPath:
          index % 2 === 0
            ? "polygon(0 0, 100% 0, 92% 100%, 0% 100%)"
            : "polygon(0 0, 100% 0, 100% 100%, 8% 100%)",
      }}
    >
      <JumbotronComponent t={t} imgUrl={imgUrl} overlayContent=" " />
    </div>
  );

  const words = (
    <div className="desktop-words">
      <div>
        <h3>{t(overlayKey)}</h3>
        {parseBackContent(t(backContent))}
      </div>
    </div>
  );

  return (
    <DesktopCardCSS>
      {index % 2 === 0 ? (
        <>
          {picture}
          {words}
        </>
      ) : (
        <>
          {words}
          {picture}
        </>
      )}
    </DesktopCardCSS>
  );
};

const CardSectionComponent = ({ t, config, language, showFlashcard }) => {
  const { subheading, cards } = config;

  return (
    <CardSectionCSS id={subheadingToIdMap[subheading]}>
      <SubHeading text={t(subheading)} />
      {showFlashcard && <MobileImgTips t={t} />}
      <div className="flashcards">
        {cards.map(({ imgUrl, backContent, overlayKey }, index) =>
          showFlashcard ? (
            <Flashcard
              key={index}
              frontContent={
                <JumbotronComponent
                  t={t}
                  imgUrl={imgUrl}
                  overlayStyle={{
                    fontFamily:
                      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                    fontSize: "max(2.5vw, 2rem)",
                    right: "2rem",
                    left: "auto",
                    bottom: "1rem",
                  }}
                  overlayKey={overlayKey}
                />
              }
              backContent={
                <JumbotronComponent
                  t={t}
                  imgUrl={imgUrl}
                  maskStyle={{ background: "rgba(255, 255, 255, 0.8)" }}
                  overlayStyle={{
                    display: "block",
                    fontSize: language === "en" ? "1.2rem" : "1.5rem",
                    fontFamily:
                      '-apple-system, "Segoe UI", "Open Sans", sans-serif',
                    top: "0px",
                    left: "0px",
                    overflowY: "auto",
                    margin: "1.5rem",
                  }}
                  overlayContent={parseBackContent(t(backContent))}
                />
              }
            />
          ) : (
            <DesktopCard
              t={t}
              imgUrl={imgUrl}
              backContent={backContent}
              overlayKey={overlayKey}
              index={index}
              key={index}
            />
          )
        )}
      </div>
    </CardSectionCSS>
  );
};

const MobileImgTips = ({ t }) => {
  return (
    <div style={{ textAlign: "center", fontSize: "12px", color: "#888" }}>{t("clickImgTip")}&gt;</div>
  )
}

const talentNurturingConfig = (mediaList, countryCode) => ({
  cards: [
    {
      imgUrl: publicIdToLocal["biel/VetTeaching2_cvcxqz"],
      overlayKey: "youth",
      backContent: "youthContent",
    },
    {
      imgUrl: publicIdToLocal["biel/biel_csr_professional_yeaypd"],
      overlayKey: "academia",
      backContent: "academiaContent",
    },
  ],
});

const environmentConfig = (mediaList, countryCode) => ({
  cards: [
    {
      imgUrl: publicIdToLocal["biel/biel_workers_love_jwh3d0"],
      overlayKey: "employee",
      backContent: "employeeContent",
    },
    {
      imgUrl: publicIdToLocal["biel/biel_green2_s65vmw"],
      overlayKey: "greenInitiative",
      backContent: "greenInitiativeContent",
    },
  ],
});

const welfareConfig = (showFlashcard, mediaList, countryCode) => ({
  cards: [
    {

      imgUrl: publicIdToLocal["biel/biel_village_tfgr11"],
      overlayKey: "rural",
      backContent: "ruralContent",
    },
    {
      imgUrl: showFlashcard ? publicIdToLocal["biel/biel_education_ohyqjr"] : publicIdToLocal["biel/csr_poverty_2_desktop_mh3lov"],
      overlayKey: "education",
      backContent: "educationContent",
    },
  ],
});

const mainConfigs = (showFlashcard, mediaList, countryCode) => [
  talentNurturingConfig(mediaList, countryCode),
  environmentConfig(mediaList, countryCode),
  welfareConfig(showFlashcard, mediaList, countryCode),
];

const CSRMainComponent = ({ t, mediaList, countryCode }) => {
  const { i18n } = useTranslation();
  const isBrowser = typeof window !== "undefined"
  const [showFlashcard, setShowFlashcard] = useState(true);

  useEffect(() => {
    if (isBrowser) {
      const updateFlashcard = () => {
        if (window.innerWidth < 1024) {
          setShowFlashcard(true);
        } else {
          setShowFlashcard(false);
        }
      };
      updateFlashcard();
      window.addEventListener("resize", updateFlashcard);
    }

  }, []);

  return (
    <CSRMainCSS language={i18n.language} showFlashcard={showFlashcard}>
      <section>
        <div>
          <div className="subtitle">
            {parseIntro(t("csrIntro"))}
            {mainConfigs(showFlashcard, mediaList, countryCode).map((config, index) => (
              <CardSectionComponent
                t={t}
                config={config}
                language={i18n.language}
                showFlashcard={showFlashcard}
                key={index}
              />
            ))}
          </div>
        </div>
      </section>
    </CSRMainCSS>
  );
};

const CSRPageContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <JumbotronComponent
        t={t}
        imgUrl={publicIdToLocal["biel/csr_jumbotron_ha0lvo"]}
        isJumbotron
      />
      <CSRMainComponent
        t={t}
      />
    </>
  );
};

const CSRPage = ({ data, location }) => {
  const { t, i18n } = useTranslation();

  return (
    <Layout location={location} t={t} i18n={i18n} >
      <SEO title={t("title")} description={t("description")} pathname={location.pathname} />
      <LocationContext.Consumer>
        {props => (
          <CSRPageContent {...props} />
        )}
      </LocationContext.Consumer>
    </Layout>
  );
};

CSRPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {ns: {in: ["csr","footer"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default CSRPage;
