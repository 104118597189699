import React from 'react'
import styled from 'styled-components'

export const JumbotronCSS = styled.div`
  position: relative;
  height: ${props => props.isJumbotron ? '60vh' : '100%'};

  background-image: ${props => props.imgUrl};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .jumbotron-overlay {
    font-family: -apple-system, BlinkMacSystemFont, "Noto Sans", 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: absolute;
    bottom: 0px; 
    left: 15%;
    color: #fff;

    font-size: min(13vw, 5rem);
  }
`

const JumbotronComponent = ({ t, imgUrl, overlayStyle, overlayKey, maskStyle, overlayContent, isJumbotron }) => {
  return (
    <JumbotronCSS imgUrl={`url(${imgUrl})`} isJumbotron={isJumbotron} >
      <div style={{
        height: '100%',
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.3) 90%, rgba(0, 0, 0, 0.4) 100%)',
        ...maskStyle,
      }} />
      <div className="jumbotron-overlay" style={overlayStyle}>
        {overlayContent || t(overlayKey || 'title')}
      </div>
    </JumbotronCSS>
  )
}

export default JumbotronComponent