import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components'
import { useSpring, animated as a } from 'react-spring';
import { Card } from 'antd';

const propTypes = {
  frontContent: PropTypes.any.isRequired,
  backContent: PropTypes.any.isRequired,
  onFlipToBack: PropTypes.func,
  onFlipToFront: PropTypes.func,
  disableFlip: PropTypes.bool,
};

const defaultProps = {
  onFlipToBack: () => {},
  onFlipToFront: () => {},
  disableFlip: false
};

const FlashcardCSS = styled.div`
  min-height: max(375px, 30vw);

  .card {
    position: absolute;
    width: max(375px, 30vw);
    height: max(375px, 30vw);
    cursor: pointer;
    will-change: transform, opacity;
    border-radius: 20px;
  }

  .card > div {
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    border-width: 2px;
    max-width: inherit;
  }

  .flashcard {
    height: max(375px, 30vw);
    width: max(375px, 30vw);
    user-select: none;
    margin: auto;

    margin-top: 1rem;
  }

  .backContentContainer {
    align-items: center;
    height: 100%;
  }

  p.backContent {
    color: #4a4a4a;
    text-align: justify;
    text-justify: inter-character;
  }
`

const Flashcard = props => {
  const { frontContent, backContent, onFlipToBack, onFlipToFront, disableFlip } = props;

  const [flipped, setFlipped] = useState(false);

  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  });

  const handleFlip = () => {
    if (disableFlip) return;
    flipped ? onFlipToFront() : onFlipToBack();
    setFlipped(prev => !prev);
  };

  return (
    <FlashcardCSS>
      {
        <div className="flashcard" onClick={handleFlip}>
          <a.div
            className="card"
            style={{ opacity: opacity.interpolate(o => 1 - o), transform, zIndex: flipped ? 0 : 1 }}
          >
            <Card hoverable style={{ borderRadius: 10 }} bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
              {frontContent}
            </Card>
          </a.div>
          <a.div
            className="card"
            style={{ opacity, transform: transform.interpolate(t => `${t} scaleX(-1)`), zIndex: flipped ? 1 : 0 }}
          >
            <Card hoverable style={{ borderRadius: 10 }} bodyStyle={{ height: '100%' }}>
              <div className="backContentContainer">
                {backContent}
              </div>
            </Card>
          </a.div>
        </div>
      }
    </FlashcardCSS>
  );
};

Flashcard.propTypes = propTypes;
Flashcard.defaultProps = defaultProps;

export default Flashcard;
